import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { setExternalAudienceInviteInfo } from "store/modules/auth/action";

function DeeplinkAudienceInvite() {

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const registeredUser = searchParams.get("registeredUser");
  const sharedAudience = searchParams.get("sharedSource");
  const audienceId = searchParams.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setExternalAudienceInviteInfo({
        marketplaceId: id,
        isRegisteredUsere: registeredUser,
        isSharedSource: sharedAudience,
        audienceId: audienceId,
      })
    );
    navigate(APP_ROUTES.LANDING.path);
  }, []);

  return <div>Fetching Information...</div>;
}

export default DeeplinkAudienceInvite;
