import React from "react";
import "./index.scss";
import { Image, Button } from "antd";
import { MenuOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import { IconDataAxle } from "images";

function DataAxleDestinationCatalog() {
  const navigate = useNavigate();
  const { id } = useParams();
  const navigateToFields = () => {
    navigate(`/dashboard/destination/setup/${"dataaxle"}/${id}`);
  };

  const handleBackClick = () => {
    // Navigate back to the previous page
    navigate(-1); // Go back one step
  };

  const handleConnectionClick = () => {
    // Navigate back to the previous page
    navigate(-2); // Go back one step
  };

  return (
    <div className="DataAxleDestinationCatalog">
      <div className="DataAxleDestinationCatalog__Fixed">
        {/* <div className="DataAxleDestinationCatalog__headerText">
          <span onClick={handleBackClick} style={{ cursor: "pointer" }}>
            <Image src={IconChevronLeft} preview={false} />
          </span>
          <span className="DataAxleDestinationCatalog__title"
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}
            onClick={handleConnectionClick}>
            Connections</span>
          <span className="DataAxleDestinationCatalog__slash">/</span>
          <span className="DataAxleDestinationCatalog__title" onClick={handleBackClick}
            onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
            onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
            style={{ cursor: "pointer" }}>
            Catalog
          </span>
          <span className="DataAxleDestinationCatalog__slash">/</span>
          <span className="DataAxleDestinationCatalog__mainTitle">Data Axle</span>
        </div> */}
        <div className="DataAxleDestinationCatalog__sourceText">
          <div>
            <Image
              src={IconDataAxle}
              preview={false}
              style={{ marginRight: "10px" }}
            />
            <span style={{ marginLeft: "15px" }}>Data Axle</span>
          </div>
        </div>
        <div className="DataAxleDestinationCatalog__bar">
          <div>
            <Button
              onClick={() => navigateToFields()}
              className="DataAxleDestinationCatalog__button"
            >
              Add Destination
              <ArrowRightOutlined className="DataAxleDestinationCatalog__btnImage" />
            </Button>
          </div>
        </div>
        <div className="SalesforceDestinationDetails__newSource">
          <Button className="SalesforceDestinationDetails__newText">
            Destination
          </Button>
        </div>
        <div className="DataAxleDestinationCatalog__exploreBar">
          <div className="DataAxleDestinationCatalog__exploreText">
            Data Axle brings businesses and customers closer together
          </div>
        </div>
        <div className="DataAxleDestinationCatalog__bottomBar"></div>
      </div>
      <div className="DataAxleDestinationCatalog__mainContainer">
        <div className="DataAxleDestinationCatalog__flexDiv">
          <div className="DataAxleDestinationCatalog__container1">
            <h6 className="DataAxleDestinationCatalog__cardHeading1">
              Compatible destination types
            </h6>
            <div className="DataAxleDestinationCatalog__textFlex">
              <div className="DataAxleDestinationCatalog__cardIcon">
                <MenuOutlined />
              </div>
              <div className="DataAxleDestinationCatalog__cardText">
                Storage
              </div>
            </div>
            <p className="DataAxleDestinationCatalog__rawText">
              Destinations for storing raw data.
              {/*<a className="DataAxleDestinationCatalog__anchorTag">Learn more</a>*/}
            </p>
          </div>
          <div className="DataAxleDestinationCatalog__container2">
            <div className="DataAxleDestinationCatalog__containFlex">
              <div className="DataAxleDestinationCatalog__line"></div>
              <div className="DataAxleDestinationCatalog__justDiv">
                <h6 className="DataAxleDestinationCatalog__cardHeading2">
                  Set up requirements
                </h6>
                <p className="DataAxleDestinationCatalog__setText1">
                  * A Data Axle account with administrator privileges
                </p>
                {/* <p className="DataAxleDestinationCatalog__setText1">
                  * Ideally, the account should have View All Records
                  permission. If you aren’t sure, ask you Salesforce
                  administrator for help.
                </p> */}
              </div>
            </div>
          </div>
        </div>

        <div className="DataAxleDestinationCatalog__flexDiv1">
          <div className="DataAxleDestinationCatalog__container3">
            <h6 className="DataAxleDestinationCatalog__overviewHeading1">
              Overview
            </h6>
            <p className="DataAxleDestinationCatalog__overviewText">
              When you enter your DataAxle credentials and hit save, we will
              sync standard DataAxle objects (and their corresponding
              properties) to any databases you have turned on (to turn on a
              database, navigate to the database tab in the navigation pane on
              the left). These objects will be written into a separate schema,
              corresponding to your project’s name. DataAxle data will reflect
              the state of the object at the time of the sync: so if a ticket
              status goes from `unassigned` to `assigned` between syncs, on its
              next sync that tickets status will be `assigned`. We will update
              DataAxle data every ~3 hours.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataAxleDestinationCatalog;
