import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { setExternalSourceInviteInfo } from "store/modules/auth/action";

function DeeplinkSourceInvite() {

  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const registeredUser = searchParams.get("registeredUser");
  const sharedSource = searchParams.get("sharedSource");
  const sourceId = searchParams.get("id");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setExternalSourceInviteInfo({
        marketplaceId: id,
        isRegisteredUsere: registeredUser,
        isSharedSource: sharedSource,
        sourceId: sourceId,
      })
    );
    navigate(APP_ROUTES.LANDING.path);
  }, []);

  return <div>Fetching Information...</div>;
}

export default DeeplinkSourceInvite;
