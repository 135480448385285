import { APP_ORIGIN } from "../../environments";
import { call } from "redux-saga/effects";
import { SET_DESTINATIONS } from "store/modules/datasets/action";

const enivironmentBasedUniqueKey = (key) => {
  return `${APP_ORIGIN}_${key}`;
};
export const USER_SESSION_KEY = enivironmentBasedUniqueKey("USER_SESSION_KEY");
export const SET_FORM_DATA = enivironmentBasedUniqueKey("SET_FORM_DATA");
export const SET_ACTIVE_KEY = enivironmentBasedUniqueKey("SET_ACTIVE_KEY");
export const SET_SOURCES = enivironmentBasedUniqueKey("SET_SOURCES");
export const SET_STEP_COMPLETION = enivironmentBasedUniqueKey(
  "SET_STEP_COMPLETION"
);
export const USER_TOKEN_KEY = enivironmentBasedUniqueKey("USER_TOKEN_KEY");
export const ACCESS_TOKEN_KEY = enivironmentBasedUniqueKey("ACCESS_TOKEN_KEY");
export const IS_SOCIAL_KEY = enivironmentBasedUniqueKey("IS_SOCIAL_KEY");
export const IS_DOMAIN_AND_USER_INFO_KEY = enivironmentBasedUniqueKey(
  "IS_DOMAIN_AND_USER_INFO_KEY"
);
export const IS_EXTERNAL_USER_INFO_KEY = enivironmentBasedUniqueKey(
  "IS_EXTERNAL_USER_INFO_KEY"
);
export const IS_EXTERNAL_INVITE_INFO_KEY = enivironmentBasedUniqueKey(
  "IS_EXTERNAL_INVITE_INFO_KEY"
);
export const IS_EXTERNAL_AUDIENCE_INVITE_INFO_KEY = enivironmentBasedUniqueKey(
  "IS_EXTERNAL_AUDIENCE_INVITE_INFO_KEY"
);
export const IS_EXTERNAL_SOURCE_INVITE_INFO_KEY = enivironmentBasedUniqueKey(
  "IS_EXTERNAL_SOURCE_INVITE_INFO_KEY"
);
export const IS_EXTERNAL_LINK_SEGMENT_PARAMS_KEY = enivironmentBasedUniqueKey(
  "IS_EXTERNAL_LINK_SEGMENT_PARAMS_KEY"
);

const saveValueInLocalStorage = (key, value) => {
  localStorage.setItem(key, JSON.stringify(value));
};
const getValueFromLocalStorage = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const saveUserSession = (userSession) => {
  saveValueInLocalStorage(USER_SESSION_KEY, userSession);
};
export const saveFormData = (formData) => {
  saveValueInLocalStorage(SET_FORM_DATA, formData);
};
export const saveActiveKey = (activeKey) => {
  saveValueInLocalStorage(SET_ACTIVE_KEY, activeKey);
};
export const saveSources = (sources) => {
  saveValueInLocalStorage(SET_SOURCES, sources);
};
export const saveDestinations = (destinations) => {
  saveValueInLocalStorage(SET_DESTINATIONS, destinations);
};
export const saveStepCompletion = (stepCompletion, action) => {
  saveValueInLocalStorage(SET_STEP_COMPLETION, stepCompletion, action);

  function clearLocalStorageData() {
    try {
      localStorage.clear();
    } catch (error) {
    }
  }
  function* handleSetFormData(action) {
    yield call(saveFormData, action.payload);
  }
  function* handleSetSources(action) {
    yield call(saveSources, action.payload);
  }
  function* handleSetDestinations(action) {
    yield call(saveDestinations, action.payload);
  }
  function* handleSetStepCompletion(action) {
    yield call(saveStepCompletion, action.payload);
  }
  function* handleClearDatasetState(action) {
    yield call(clearLocalStorageData);
  }
  function* handleActiveKey(activeKey) {
    yield call(saveActiveKey(action.payload))
  }

};
export const getSavedUserSession = () => {
  return getValueFromLocalStorage(USER_SESSION_KEY);
};

export const saveUserToken = (userToken) => {
  saveValueInLocalStorage(USER_TOKEN_KEY, userToken);
};

export const getSavedUserToken = () => {
  return getValueFromLocalStorage(USER_TOKEN_KEY);
};

export const saveAccessToken = (accessToken) => {
  saveValueInLocalStorage(ACCESS_TOKEN_KEY, accessToken);
};

export const getSavedAccessToken = () => {
  return getValueFromLocalStorage(ACCESS_TOKEN_KEY);
};

export const saveSocialKey = (isSocial) => {
  saveValueInLocalStorage(IS_SOCIAL_KEY, isSocial);
};
export const getSocialKey = () => {
  return getValueFromLocalStorage(IS_SOCIAL_KEY);
};

export const saveDomainAndUserInfo = (info) => {
  saveValueInLocalStorage(IS_DOMAIN_AND_USER_INFO_KEY, info);
};

export const getDomainAndUserInfo = () => {
  return getValueFromLocalStorage(IS_DOMAIN_AND_USER_INFO_KEY);
};

export const saveExternalUserInfo = (info) => {
  saveValueInLocalStorage(IS_EXTERNAL_USER_INFO_KEY, info);
};

export const getExternalUserInfo = () => {
  return getValueFromLocalStorage(IS_EXTERNAL_USER_INFO_KEY);
};

export const saveExternalInviteInfo = (info) => {
  saveValueInLocalStorage(IS_EXTERNAL_INVITE_INFO_KEY, info);
};

export const getExternalInviteInfo = () => {
  return getValueFromLocalStorage(IS_EXTERNAL_INVITE_INFO_KEY);
};

export const saveExternalAudienceInviteInfo = (info) => {
  saveValueInLocalStorage(IS_EXTERNAL_AUDIENCE_INVITE_INFO_KEY, info);
};

export const getExternalAudienceInviteInfo = () => {
  return getValueFromLocalStorage(IS_EXTERNAL_AUDIENCE_INVITE_INFO_KEY);
};

export const saveExternalSourceInviteInfo = (info) => {
  saveValueInLocalStorage(IS_EXTERNAL_SOURCE_INVITE_INFO_KEY, info);
};

export const getExternalSourceInviteInfo = () => {
  return getValueFromLocalStorage(IS_EXTERNAL_SOURCE_INVITE_INFO_KEY);
};

export const saveExternalLinkSegmentParams = (info) => {
  saveValueInLocalStorage(IS_EXTERNAL_LINK_SEGMENT_PARAMS_KEY, info);
};

export const getExternalLinkSegmentParams = () => {
  return getValueFromLocalStorage(IS_EXTERNAL_LINK_SEGMENT_PARAMS_KEY);
};
