import {
  SET_USER_SESSION,
  SET_USER_TOKEN,
  IS_SOCIAL_KEY,
  DOMAIN_AND_USER_INFO_KEY,
  EXTERNAL_USER_INFO_KEY,
  EXTERNAL_INVITE_INFO_KEY,
  EXTERNAL_AUDIENCE_INVITE_INFO_KEY,
  EXTERNAL_LINK_SEGMENT_PARAMS_KEY,
  CLEAR_AUTH_STATE,
  SET_LEFTNAV_OPTIONS,
  EXTERNAL_SOURCE_INVITE_INFO_KEY,
} from "./action";

const initialState = {
  userSession: null,
  isAuthenticated: false,
  userToken: null,
  isSocialKey: false,
  domainAndUserInfo: null,
  externalUserInfo: null,
  externalLinkSegmentParams: null,
  externalInviteInfo: null,
  externalAudienceInviteInfo: null,
  externalSourceInviteInfo: null,
  leftNavOptions: null
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_SESSION:
      return {
        ...state,
        userSession: action.payload,
        isAuthenticated: !!action.payload,
      };
    case SET_LEFTNAV_OPTIONS:
      return {
        ...state,
        leftNavOptions: action.payload,
      };
    case SET_USER_TOKEN:
      return {
        ...state,
        userToken: action.payload,
      };
    case IS_SOCIAL_KEY:
      return {
        ...state,
        isSocialKey: action.payload,
      };
    case DOMAIN_AND_USER_INFO_KEY:
      return {
        ...state,
        domainAndUserInfo: action.payload,
      };
    case EXTERNAL_USER_INFO_KEY:
      return {
        ...state,
        externalUserInfo: action.payload,
      };
    case EXTERNAL_INVITE_INFO_KEY:
      return {
        ...state,
        externalInviteInfo: action.payload,
      };
    case EXTERNAL_AUDIENCE_INVITE_INFO_KEY:
      return {
        ...state,
        externalAudienceInviteInfo: action.payload,
      };
    case EXTERNAL_SOURCE_INVITE_INFO_KEY:
      return {
        ...state,
        externalSourceInviteInfo: action.payload,
      };
    case EXTERNAL_LINK_SEGMENT_PARAMS_KEY:
      return {
        ...state,
        externalLinkSegmentParams: action.payload,
      };
    case CLEAR_AUTH_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default authReducer;
