import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Space,
  Table,
  Tag,
  Typography,
  Avatar,
  Spin,
  Popover,
  Modal,
  Image,
  Select,
  Input,
  notification,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { SearchOutlined, DownloadOutlined, ShareAltOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./index.scss";
import { IconSalesforce, IconThreeDots } from "images";
import ShareModal from "components/Datasets/Explorer/Unified/MyDatasets/ShareModal";

const { Option } = Select;

const DatasetsDesktop = ({
  datasetsListData,
  loader,
  handlePageNumber,
  hasMore,
  downloadDatasetLink,
  deleteDataset,
}) => {
  const navigate = useNavigate();
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchInput, setSearchInput] = useState("");

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(null);
  const [filteredData, setFilteredData] = useState([]);

  const handleDelete = (record) => {
    setSelectedAudience(record);
    setIsModalVisible(true);
  };
  const handleShare = (record) => {
    setSelectedAudience(record);
    setIsShareModalVisible(true);
  };

  const confirmDelete = () => {
    callDeleteDataset(
      selectedAudience?.dataset_id,
      selectedAudience?.dataset_name
    );
    setIsModalVisible(false);
    setSelectedAudience(null);
  };
  const confirmShare = () => {
    callDeleteDataset(
      selectedAudience?.dataset_id,
      selectedAudience?.dataset_name
    );
    setIsShareModalVisible(false);
    setSelectedAudience(null);
  };

  const cancelDelete = () => {
    setIsModalVisible(false);
    setSelectedAudience(null);
  };
  const handleCancelShare = () => {
    setIsShareModalVisible(false);
    setSelectedAudience(null);
  };

  const columns = [
    {
      title: <span className="bold-title">NAME, CREATED, TAGS</span>,
      dataIndex: "name",
      render: (text, record) => (
        <div className="datasetsListContainer__datasetInfo">
          <p className="datasetsListContainer__p">
            {updateAudienceName(record.dataset_name)}
          </p>
          <small className="datasetsListContainer__small">
            Created By {changetheDatasetName(record)}
          </small>
        </div>
      ),
      className: "table-row",
    },
    {
      title: <span className="bold-title">STATUS</span>,
      dataIndex: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Offline":
            color = "#cccccc";
            break;
          case "Active":
            color = "#84d830";
            break;
          case "Unavailable":
            color = "red";
            break;
          default:
            color = "default";
        }
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "start",
              alignItems: "center",
            }}
          >
            <Avatar
              size={10}
              shape="circle"
              style={{ backgroundColor: color, marginRight: "10px" }}
            />
            {status == "Active" ? "Live" : "Disabled"}
          </div>
        );
      },
      className: "table-row",
    },
    {
      title: <span className="bold-title">DESTINATION</span>,
      dataIndex: "destination",
      render: (text, record) => (
        <Space style={{ marginLeft: "25px" }}>
          {record?.destination_ids === "20" ? (
            <Image
              src="https://palisade-api-connectors.s3.us-west-2.amazonaws.com/source_icons/data_axle.svg"
              width={25}
              height={25}
              style={{ marginLeft: "4px" }}
              preview={false}
            />
          ) : (
            <Image
              src={IconSalesforce}
              width={30}
              height={30}
              style={{ marginLeft: "4px" }}
              preview={false}
            />
          )}

        </Space>
      ),
      className: "table-row",
    },
    {
      title: <span className="bold-title">ACTION</span>,
      dataIndex: "action",
      render: (text, record) => (
        <Space style={{ marginLeft: "5px" }}>
          {/* Download Button */}
          <DownloadOutlined
            style={{
              fontSize: "20px",
              cursor: "pointer",
              color: "#0f0f60", // Navy blue color
              marginRight: "15px", // Space after download icon
            }}
            onClick={() => downloadDataset(record?.dataset_id, record?.dataset_name)}
          />

          {/* Popover for More Actions */}
          <Popover
            content={
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {/* Delete Button */}
                <Button
                  icon={<DeleteOutlined />}
                  type="text"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#ff4d4f", // Red background for delete
                    border: "none",
                    borderRadius: "4px",
                    padding: "5px 10px",
                  }}
                  onClick={() => handleDelete(record)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#e84141") // Darker red on hover
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#ff4d4f") // Reset to original color
                  }
                >
                  Delete
                </Button>

                {/* Share Button */}
                <Button
                  icon={<ShareAltOutlined />}
                  type="text"
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#4CAF50", // Green background for share
                    border: "none",
                    borderRadius: "4px",
                    padding: "5px 10px",
                  }}
                  onClick={() => handleShare(record)}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#45a049") // Darker green on hover
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#4CAF50") // Reset to original color
                  }
                >
                  Share
                </Button>
              </div>
            }
            trigger="click"
          >
            <Image
              src={IconThreeDots}
              preview={false}
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
              }}
            />
          </Popover>
        </Space>

      ),
      className: "table-row",
    },
  ];

  const handleSearch = (e) => {
    setSearchInput(e.target.value.toLowerCase());
  };

  const downloadDataset = async (datasetId, datasetName) => {
    try {
      // Call the API to get the signed URL
      const response = await downloadDatasetLink(datasetId);
      // Check if the response contains the signed URL
      if (response.data && !response.data.error) {
        const signedUrl = response.data.data.signedUrl;
        // Notify the user that the download is starting
        notification.success({
          message: "Download Started",
          description: `Your download for the dataset "${datasetName}" has started.`,
          duration: 3,
        });
        // Create a link element and trigger the download
        const link = document.createElement("a");
        link.href = signedUrl;
        // Use the dataset name as the file name
        link.download = `${datasetName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        // Notify the user about the error
        notification.error({
          message: "Download Failed",
          description:
            response.data.message || "Could not generate the signed URL.",
          duration: 3,
        });

        console.error(
          "Failed to generate the signed URL:",
          response.data.message
        );
      }
    } catch (error) {
      // Notify the user about the error
      notification.error({
        message: "Error",
        description:
          "An error occurred while downloading the dataset. Please try again.",
        duration: 3,
      });

      console.error("Error downloading dataset:", error);
    }
  };

  useEffect(() => {
    const filtered = filterByStatus(datasetsListData).filter((item) =>
      item.dataset_name.toLowerCase().includes(searchInput)
    );
    setFilteredData(filtered);
  }, [datasetsListData, searchInput]);

  // const filteredData = filterByStatus(datasetsListData).filter((item) =>
  //   item.dataset_name.toLowerCase().includes(searchInput)
  // );

  const callDeleteDataset = async (datasetId, datasetName) => {
    try {
      const response = await deleteDataset(datasetId);
      if (response.data && !response.data.error) {
        notification.success({
          message: "Audidence Deleted",
          description: `Your Audidence "${datasetName}" deleted successfully.`,
          duration: 2,
        });

        setFilteredData((prevList) =>
          prevList.filter((dataset) => dataset.dataset_id !== datasetId)
        );
      } else {
        // Notify the user about the error
        notification.error({
          message: "Deletion Failed",
          description: response.data.message || "Could not delete an Audience",
          duration: 3,
        });

        console.error(
          "Failed to generate the signed URL:",
          response.data.message
        );
      }
    } catch (error) {
      // Notify the user about the error
      notification.error({
        message: "Error",
        description:
          "An error occurred while downloading the dataset. Please try again.",
        duration: 3,
      });

      console.error("Error downloading dataset:", error);
    }
  };
  const filterByStatus = (data) => {
    switch (statusFilter) {
      case "all":
        return data; // Show all datasets
      case "enabled":
        return data.filter((item) => item.status == "Active"); // Show only enabled datasets
      case "disabled":
        return data.filter((item) => item.status == false); // Show only disabled datasets
      default:
        return data;
    }
  };

  const changetheDatasetName = (record) => {
    var name = "Smith";
    if (
      record.user_name === "Kimball Belmont Belmont" &&
      record.dataset_name === "launch"
    ) {
      name = "Ben Moore";
    } else if (
      record.user_name === "Kimball Belmont Belmont" &&
      record.dataset_name === "First Test Audience"
    ) {
      name = "Brycen Curry";
    } else if (
      record.user_name === "Muhammad ZamanTest" &&
      record.dataset_name === "New Dataset by MZ"
    ) {
      name = "Cohen Hurley";
    } else if (
      record.user_name === "Muhammad ZamanTest" &&
      record.dataset_name === "Test Dataset"
    ) {
      name = "Ronald Camacho";
    } else if (record.user_name === "Atul Gupta") {
      name = "Ronald Camacho";
    } else {
      name = record.user_name;
    }
    return name;
  };

  const updateAudienceName = (text) => {
    var name = "California Homeowners, 60+";
    if (text === "First Test Audience") {
      name = "California Homeowners, 60+";
    } else if (text === "launch") {
      name = "All-USA Homeowners, Good & Excellent Credit";
    } else if (text === "Test Dataset") {
      name = "All Homeowners in Top Area Codes";
    } else if (text === "New Dataset by MZ") {
      name = "Filled Home Services Lead in Past 3 Months";
    } else if (text === "Haq 4 Yrrrrr") {
      name = "Filled Home Services Lead in Past 3 Months";
    } else {
      name = text;
    }
    return name;
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  return (
    <div className="datasetsListContainer">
      <div className="datasetsListContainer__headerDiv">
        <div className="datasetsListContainer__header">
          <Typography.Text className="datasetsListContainer__h1Text">
            List of Audiences
          </Typography.Text>
          <p className="datasetsListContainer__h1Description">
            These are the audiences that you have created
          </p>
        </div>
        <Input
          className="datasetsListContainer__input"
          type="search"
          placeholder="Search for audiences by name"
          onChange={handleSearch}
          value={searchInput}
          prefix={<SearchOutlined style={{ color: "grey" }} />}
        />
        <Button
          className="datasetsListContainer__newDatasetBtn"
          onClick={() => navigate(`/dashboard/datasets/datasets/steps`)}
          icon={<PlusOutlined />}
        >
          New audience
        </Button>
      </div>
      <div className="datasetsListContainer__filter">
        <Select
          className="datasetsListContainer__status-filter"
          defaultValue="all"
          onChange={handleStatusChange}
          style={{ width: 165 }} // Adjust width as needed
        >
          <Option value="all">
            <span className="Status all-icon"></span> All
          </Option>
          <Option value="enabled">
            <span className="Status enabled-icon"></span> Live
          </Option>
          <Option value="disabled">
            <span className="Status disabled-icon"></span> Disabled
          </Option>
        </Select>
      </div>
      {loader ? (
        <div className="loading-container">
          <Spin />
        </div>
      ) : (
        <>
          <Table
            columns={columns}
            dataSource={filteredData}
            pagination={true}
          />
          <Modal
            title="Confirm Delete"
            visible={isModalVisible}
            onOk={confirmDelete}
            onCancel={cancelDelete}
            okText="Yes"
            cancelText="No"
          >
            {selectedAudience && (
              <p>
                Are you sure you want to delete Audience{" "}
                <strong>{selectedAudience.dataset_name}</strong>?
              </p>
            )}
          </Modal>

          <ShareModal
            visible={isShareModalVisible}
            datasetId={selectedAudience ? selectedAudience.dataset_id : ""}
            datasetName={selectedAudience ? selectedAudience.dataset_name : ""}
            onCancel={() => handleCancelShare()}
          />
          {!hasMore && <div style={{ textAlign: "center" }}>No more data</div>}
        </>
      )}
    </div>
  );
};

export default DatasetsDesktop;
