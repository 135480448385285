import React, { useEffect } from "react";
import { Tabs } from "antd";
import "./index.scss";
import PlacesSearch from "../PlacesSearch";
import CompaniesSearch from "../CompaniesSearch";
import EventsSearch from "../EventsSearch";
import PeopleSearch from "../PeopleSearch";
import ProfileSearch from "../ProfileSearch";
import Overview from "../Overview";
import { useDispatch, useSelector } from "react-redux";
import { updateDataset, updateSource } from "api/Datasets";
import { setExternalAudienceInviteInfo, setExternalSourceInviteInfo } from "store/modules/auth/action";

const { TabPane } = Tabs;
const Explorer = () => {

  const dispatch = useDispatch();
  const authReducer = useSelector((state) => state.authReducer);
  const loggedInUserId = authReducer.userSession.userId;
  const externalAudienceInfo = authReducer.externalAudienceInviteInfo;
  const externalSourceInfo = authReducer.externalSourceInviteInfo;

  console.log("externalAudienceInfo: ", externalAudienceInfo);
  console.log("externalSourceInfo: ", externalSourceInfo);


  useEffect(() => {
    if (externalAudienceInfo) {
      const audiencePayload = {
        shareAudienceId: externalAudienceInfo.audienceId,
        sharedWithId: loggedInUserId,
        status: "shared",
      };

      console.log("audiencePayload: ", audiencePayload);
      updateDataset(audiencePayload)
        .then(() => {
          dispatch(setExternalAudienceInviteInfo(null));
        })
        .catch((error) => {
          console.error("Error updating dataset:", error);
        });
    }

    if (externalSourceInfo) {
      const sourcePayload = {
        shareSourceId: externalSourceInfo.sourceId,
        sharedWithId: loggedInUserId,
        status: "shared",
      };

      console.log("sourcePayload: ", sourcePayload);
      updateSource(sourcePayload)
        .then(() => {
          dispatch(setExternalSourceInviteInfo(null));
        })
        .catch((error) => {
          console.error("Error updating dataset:", error);
        });
    }
  }, []);

  return (
    <Tabs defaultActiveKey="1" className="profile-explorer__tabs">
      <TabPane tab="Overview" key="1">
        <Overview />
      </TabPane>
      <TabPane tab="People" key="2">
        <PeopleSearch />
      </TabPane>
      <TabPane tab="Places" key="3">
        <PlacesSearch />
      </TabPane>
      <TabPane tab="Companies" key="4">
        <CompaniesSearch />
      </TabPane>
      <TabPane tab="Events" key="5">
        <EventsSearch />
      </TabPane>
    </Tabs>
  );
};

export default Explorer;
