export const SET_USER_SESSION = "SET_USER_SESSION";
export const SET_LEFTNAV_OPTIONS = "SET_LEFTNAV_OPTIONS";
export const SET_USER_TOKEN = "SET_USER_TOKEN";
export const SET_ACCESS_TOKEN = "SET_ACCESS_TOKEN";
export const IS_SOCIAL_KEY = "IS_SOCIAL_KEY";
export const DOMAIN_AND_USER_INFO_KEY = "DOMAIN_AND_USER_INFO_KEY";
export const EXTERNAL_USER_INFO_KEY = "EXTERNAL_USER_INFO_KEY";
export const EXTERNAL_INVITE_INFO_KEY = "EXTERNAL_INVITE_INFO_KEY";
export const EXTERNAL_AUDIENCE_INVITE_INFO_KEY = "EXTERNAL_AUDIENCE_INVITE_INFO_KEY";
export const EXTERNAL_SOURCE_INVITE_INFO_KEY = "EXTERNAL_SOURCE_INVITE_INFO_KEY";
export const EXTERNAL_LINK_SEGMENT_PARAMS_KEY =
  "EXTERNAL_LINK_SEGMENT_PARAMS_KEY";
export const CLEAR_AUTH_STATE = "CLEAR_AUTH_STATE";

export const setUserSession = (payload) => {
  return { type: SET_USER_SESSION, payload: payload };
};

export const setLeftNavOptions = (payload) => {
  return { type: SET_LEFTNAV_OPTIONS, payload: payload };
};

export const setUserToken = (payload) => {
  return { type: SET_USER_TOKEN, payload: payload };
};

export const setAccessToken = (payload) => {
  return { type: SET_ACCESS_TOKEN, payload: payload };
};

export const setIsSocial = (payload) => {
  return { type: IS_SOCIAL_KEY, payload: payload };
};
export const setDomainAndUserInfo = (payload) => {
  return { type: DOMAIN_AND_USER_INFO_KEY, payload: payload };
};
export const setExternalUserInfo = (payload) => {
  return { type: EXTERNAL_USER_INFO_KEY, payload: payload };
};
export const setExternalInviteInfo = (payload) => {
  return { type: EXTERNAL_INVITE_INFO_KEY, payload: payload };
};
export const setExternalAudienceInviteInfo = (payload) => {
  return { type: EXTERNAL_AUDIENCE_INVITE_INFO_KEY, payload: payload };
};
export const setExternalSourceInviteInfo = (payload) => {
  return { type: EXTERNAL_SOURCE_INVITE_INFO_KEY, payload: payload };
};
export const setExternalLinkSegmentParams = (payload) => {
  return { type: EXTERNAL_LINK_SEGMENT_PARAMS_KEY, payload: payload };
};
export const clearAuthState = (payload) => {
  return { type: CLEAR_AUTH_STATE, payload: payload };
};
