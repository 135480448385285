import { divide } from "lodash";
import "./index.scss";
import { useEffect, useState } from "react";
import { IconRevcloud, Facebookads } from "images";
import FacebookLogin from "react-facebook-login";
import { useNavigate, useParams } from "react-router-dom";
import {
  createFacebookAdProfile,
  createProfile,
  getAPIVersionData,
} from "api/Connectors";
import { Button, message } from "antd";

const ConnectFacebookAds = () => {
  const [apiVersions, setApiVersions] = useState([]);
  const [selectedVersion, setSelectedVersion] = useState("");
  const [name, setName] = useState("");
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [spinning, setSpinning] = useState(false);

  useEffect(async () => {
    try {
      const response = await getAPIVersionData("FacebookAds");
      if (response.data.error === false) {
        setApiVersions(response.data.data);
        setSelectedVersion(response.data.data[0]);
      } else {
        console.error("Error fetching API versions:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching API versions:", error);
    }
  }, []);

  const handleVersionChange = (e) => {
    setSelectedVersion(e.target.value);
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "255822244135692",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v19.0",
      });
    };
    (function (d, s, id) {
      let js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const FacebookInfoMessage = (
    <div>
      <strong>
        To integrate your Facebook Ads account with Palisade AI, follow these
        steps:
      </strong>
      <br />
      1. Log in to Facebook Ads, go to the Advance Features, Advance Settings,
      Channels, API.
      <br />
      2. Under OAuth Clients, select the OAuth client you created.
      <br />
      3. Replace your Redirect URLs with:
      <code>
        https://app.palisade.ai/dashboard/datasets/sources/facebookads/9
      </code>
      <br />
      4. Copy the Client Id and Client Secret provided in the OAuth client
      settings.
    </div>
  );
  const navigate = useNavigate();
  const { id } = useParams();
  const responseFacebook = async (response) => {
    setSpinning(true);

    const requestBody = {
      clientId: clientId,
      clientSecret: clientSecret,
      connectionName: name,
      connectorId: 9,
      redirectUri: `${window.location.origin}/dashboard/sources/setup/facebookAds/9`,
      authCode: response.accessToken,
      apiVersion: selectedVersion,
    };

    console.log("request body Facebook Ads: ", requestBody);
    try {
      const res = await createFacebookAdProfile(requestBody);
      message.success(res?.data?.message);
      // Navigate to specified location
      navigate(`/dashboard/datasets/sources`);
    } catch (err) {
      // Handle errors if any
      console.error("Error:", err);
    } finally {
      // Set spinning state back to false after processing
      setSpinning(false);
    }
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleClientIdChange = (event) => {
    setClientId(event.target.value);
  };
  const handleClientSecretChange = (event) => {
    setClientSecret(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <div className="ConnectFacebookAds__component">
        <h1 className="ConnectFacebookAds__mainHeading">
          Connect to Facebook Ads
        </h1>
        <div className="ConnectFacebookAds__div">
          <form onSubmit={handleSubmit}>
            <div>
              <h6 className="ConnectFacebookAds__heading">API Version *</h6>
              <select
                className="ConnectFacebookAds__input"
                value={selectedVersion}
                onChange={handleVersionChange}
              >
                {apiVersions.map((version) => (
                  <option key={version} value={version}>
                    {version}
                  </option>
                ))}
              </select>
              <p className="ConnectFacebookAds__para">
                Select API version that you want to use for Facebook Ads
              </p>
            </div>
            <div>
              <h6 className="ConnectFacebookAds__heading">Name *</h6>
              <input
                className="ConnectFacebookAds__input"
                placeholder="Enter Name"
                value={name}
                onChange={handleNameChange}
              />
              <p className="ConnectFacebookAds__para">
                Identifies this source within your workspace, and tipically
                reflects the name of the application
              </p>
            </div>

            <div>
              <h6 className="ConnectFacebookAds__heading">Client Id</h6>
              <input
                className="ConnectFacebookAds__input"
                placeholder="Enter Client Id"
                value={clientId}
                onChange={handleClientIdChange}
              />
              <p className="ConnectFacebookAds__para">Add client secret Id</p>
            </div>
            <div>
              <h6 className="ConnectFacebookAds__heading">Client Secret</h6>
              <input
                className="ConnectFacebookAds__input"
                placeholder="Enter Client Secret"
                value={clientSecret}
                onChange={handleClientSecretChange}
              />
              <p className="ConnectFacebookAds__para">Add client secret key</p>
            </div>
            <div>
              <div className="ConnectFacebookAds__buttonsDiv">
                <div>
                  <FacebookLogin
                    appId="255822244135692"
                    autoLoad={false}
                    fields="name,email,picture"
                    callback={responseFacebook}
                    scope="ads_read,ads_management"
                    textButton="Authenticate"
                    cssClass="ConnectFacebookAds__button"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ConnectFacebookAds;
