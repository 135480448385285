import React from "react";
import "./index.scss";
import { Input, Button, message, Spin, Space, Select, Image, Popover, Tooltip } from "antd";
import { PlusOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { SearchOutlined } from "@ant-design/icons";
import Flex from "screens/Chat/Flex";
import { Table } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { getSavedAccessToken } from "store/localStorage";
import { clearAuthState } from "store/modules/auth/action";
import { clearFileOnboardingState } from "store/modules/fileOnboard/action";
import { truncateText } from "utils";
import { IconThreeDots } from "images";
import ShareSourcesModal from "components/Datasets/Explorer/Unified/MyDatasets/ShareSourcesModal";

const { Option } = Select;

function UploadTable() {
  const userToken = getSavedAccessToken();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filteredNewSources, setFilteredNewSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasRunOnce, setHasRunOnce] = useState(false);
  const [statusFilter, setStatusFilter] = useState("all");
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [selectedSource, setSelectedSource] = useState(null);


  const apiUrl =
    "https://vnziozspx3.execute-api.us-west-2.amazonaws.com/dev/source";
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${userToken}`,
  };

  const tableContainerStyle = {
    height: "calc(100vh - 300px)",
    overflow: "auto",
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "source",
      key: "name",
      render: (text, record) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={text.icon_url}
            alt="icon"
            style={{ width: 30, height: 24, marginRight: 10 }}
          />
          <div>
            <a>{truncateText(25, record.name)}</a>
          </div>
        </div>
      ),
    },
    {
      title: "STATUS",
      dataIndex: "is_active",
      key: "active",
      render: (text) => (
        <div className="statusFlex">
          {text === true && (
            <>
              <div className="Status"></div>
              <div>Enabled</div>
            </>
          )}
          {text === false && (
            <>
              <div className="InActiveStatus"></div>
              <div>Disabled</div>
            </>
          )}
          {typeof text !== "boolean" && (
            <>
              <div>Not applicable</div>
            </>
          )}
        </div>
      ),
    },
    {
      title: "CONNECTION TYPE",
      dataIndex: ["source", "source_type"],
      key: "type",
    },
    {
      title: "CATEGORY",
      dataIndex: ["source", "source_category"],
      key: "category",
    },
    {
      title: <span className="bold-title">ACTION</span>,
      dataIndex: "action",
      render: (text, record) => (
        <Space style={{ marginLeft: "5px" }}>
          <Popover
            content={
              <div>
                {/* Share Button */}
                <Button
                  icon={<ShareAltOutlined />}
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#4CAF50", // Initial green button
                    border: "none",
                    borderRadius: "4px",
                    padding: "5px 10px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent row click
                    handleShare(record);
                  }}
                  onMouseEnter={(e) =>
                    (e.currentTarget.style.backgroundColor = "#45a049") // Darker green on hover
                  }
                  onMouseLeave={(e) =>
                    (e.currentTarget.style.backgroundColor = "#4CAF50") // Reset to original green
                  }
                >
                  Share
                </Button>
              </div>
            }
            trigger="click"
          >
            <Image
              src={IconThreeDots}
              preview={false}
              style={{
                cursor: "pointer",
                width: "20px",
                height: "20px",
              }}
              onClick={(e) => e.stopPropagation()} // Prevent row click
            />
          </Popover>
        </Space>


      ),
      className: "table-row",
    }

  ];


  const handleRowClick = (record) => {
    const sourceId = record.client_source_id;
    const connectionName = record.name;
    const sourceName = record?.source?.name;
    const active = record?.is_active;
    const connectorId = record?.source?.id;
    navigate(
      `/dashboard/datasets/connector/overview/${sourceId}/${sourceName}/${connectionName}/${connectorId}/${active}`
    );
  };

  const handleShare = (record) => {
    setSelectedSource(record);
    setIsShareModalVisible(true);
  };

  const handleCancelShare = () => {
    setIsShareModalVisible(false);
    setSelectedSource(null);
  };

  useEffect(() => {
    if (!hasRunOnce) {
      setHasRunOnce(true);
      getListOfSources();
    }
  }, [filteredData]);

  const getListOfSources = () => {
    setLoading(true);
    axios
      .get(apiUrl, { headers })
      .then((response) => {
        setFilteredData(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 403 || error.response.status === 401) {
          message.error(error?.response?.data?.message);
          dispatch(clearFileOnboardingState(null));
          dispatch(clearAuthState(null));
        } else {
          message.error(error?.response?.data?.message);
        }
      });
  };

  const handleSearch = (e) => {
    const inputValue = e.target.value.toLowerCase();
    setSearchInput(inputValue);

    const filterData = (data, stateSetter) => {
      const filtered = data.filter((item) =>
        item.name.toLowerCase().includes(inputValue)
      );
      stateSetter(filtered);
    };

    filterData(filteredData, setFilteredNewSources);
  };

  const handleStatusChange = (value) => {
    setStatusFilter(value);
  };

  const filterByStatus = (data) => {
    // Exclude items with 'Not applicable' status
    const applicableData = data.filter((item) => typeof item.is_active === "boolean");

    switch (statusFilter) {
      case "all":
        return applicableData; // Show all items with applicable status

      case "enabled":
        return applicableData.filter((item) => item.is_active === true); // Show only enabled items

      case "disabled":
        return applicableData.filter((item) => item.is_active === false); // Show only disabled items

      case "in-progress":
        return applicableData.filter((item) => item.status === "in-progress"); // Show only items with status 'in-progress'

      case "no-recent-data":
        return applicableData.filter((item) => item.status === "no-recent-data"); // Show only items with status 'no-recent-data'

      default:
        return []; // If no match, show nothing
    }
  };

  const filteredDataByStatus = filterByStatus(filteredData);

  const NavigateCatalogList = () => {
    navigate("/dashboard/datasets/sources/catalogList");
  };

  return (
    <>
      <div className="UploadTable">
        {/* <div className="UploadTable__sourceText">
          <span>My sources</span>
        </div> */}

        <div className="UploadTable__bar">
          <Select
            className="UploadTable__status-filter"
            defaultValue="all"
            onChange={handleStatusChange}
            style={{ width: 165 }} // Adjust width as needed
          >
            <Option value="all">
              <span className="Status all-icon"></span> All
            </Option>
            <Option value="enabled">
              <span className="Status enabled-icon"></span> Enabled
            </Option>
            <Option value="disabled">
              <span className="Status disabled-icon"></span> Disabled
            </Option>
            <Option value="in-progress">
              <span className="Status in-progress-icon"></span> In Progress
            </Option>
            <Option value="no-recent-data">
              <span className="Status no-recent-data-icon"></span> No Recent
              Data
            </Option>
          </Select>
          <Input
            className="UploadTable__input"
            type="search"
            placeholder="Search for sources by name"
            onChange={handleSearch}
            value={searchInput}
            prefix={<SearchOutlined style={{ color: "grey" }} />}
          />
          <div>
            <Button
              onClick={() => NavigateCatalogList()}
              className="UploadTable__button"
              icon={<PlusOutlined />} // Add the plus icon here
            >
              Add Source
            </Button>
          </div>
        </div>

        <div className="UploadTable__tableDiv" style={tableContainerStyle}>
          {loading ? (
            <div className="UploadTable__loading-container">
              <Spin />
            </div>
          ) : (
            <>
              <Table
                columns={columns}
                dataSource={
                  searchInput
                    ? filterByStatus(filteredNewSources)
                    : filteredDataByStatus
                }
                pagination={true}
                onRow={(record) => {
                  return {
                    onClick: () => {
                      handleRowClick(record);
                    },
                    onMouseEnter: (e) => {
                      e.currentTarget.style.cursor = "pointer";
                    },
                    onMouseLeave: (e) => {
                      e.currentTarget.style.cursor = "default";
                    },
                  };
                }}
              />
              <ShareSourcesModal
                visible={isShareModalVisible}
                sourceId={selectedSource ? selectedSource.client_source_id : ""}
                sourceName={selectedSource ? selectedSource.name : ""}
                onCancel={() => handleCancelShare()}
              />
              {/* {!hasMore && <div style={{ textAlign: "center" }}>No more data</div>} */}
            </>

          )}
        </div>
      </div>
    </>
  );
}

export default UploadTable;
