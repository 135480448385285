import API from "..";

export const getUserEntities = (workspaceId) => {
  return API.get(`/dataset/entities?workspaceId=${workspaceId}`);
};

export const getUserSources = (sourcedOnboarded, eventType) => {
  const queryString = `sourcedOnboarded=${sourcedOnboarded}&eventType=${eventType}`;
  return API.get(`/dataset/sources?${queryString}`);
};

export const getUserDestinations = (destinationsOnboarded) => {
  const queryString = `destinationsConfigured=${destinationsOnboarded}`;
  return API.get(`/dataset/destinations?${queryString}`);
};

export const getDatasetsList = (payload) => {
  return API.get("/dataset", payload); // Include the page parameter dynamically with other payload param;
};
export const testQuery = (payload) => {
  return API.post("/dataset/validity", payload);
};

export const createDatasetList = (payload) => {
  return API.post("/dataset/createList", payload);
};

export const getPreviewSheetLink = (payload) => {
  const { tmpId, listId } = payload;
  const queryString = `?tmpId=${tmpId}&listId=${listId}`;
  // return API.get(`/dataset/preview${queryString}`);
  return API.get(
    `https://dataset-preview.onrender.com/api/preview-link${queryString}`
  );
};

export const createDatasetAPI = (payload) => {
  return API.post("/dataset/create", payload);
};

export const handleTransformCreation = (payload) => {
  return API.post("/dataset/transformQuery", payload);
};

export const getDestinationExtensions = (payload) => {
  return API.post("/dataset/destination/dataExtensions", payload);
};

export const createDestinationExtension = (payload) => {
  return API.post("/dataset/destination/create/dataExtension", payload);
};

export const getExplorerIdentitiesCount = () => {
  return API.get("/dataset/explorerIdentiesCount");
};

export const getPast30DaysRecords = () => {
  return API.get("/dataset/past30daysRecords");
};

export const downloadDatasetLink = (datasetId) => {
  return API.get(`/dataset/download?datasetId=${datasetId}`);
};

export const deleteDataset = (datasetId) => {
  return API.delete(`/dataset/deleteDateset/${datasetId}`);
};

export const shareDataset = (payload) => {
  return API.post("/dataset/shareAudience", payload);
};

export const updateDataset = (payload) => {
  return API.post("/dataset/updateSharedAudience", payload);
};

export const audienceSharedUsers = (datasetId) => {
  return API.get(`/dataset/audienceSharedUsers?datasetId=${datasetId}`);
};

export const shareSource = (payload) => {
  return API.post("/connector/shareSource", payload);
};

export const updateSource = (payload) => {
  return API.post("/connector/updateSharedSource", payload);
};

export const sourceSharedUsers = (datasetId) => {
  return API.get(`/connector/sourceSharedUsers?sourceId=${datasetId}`);
};
