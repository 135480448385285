import React, { useState, useEffect } from "react";
import { Modal, Input, Button, message, List, Dropdown, Menu, Empty, Spin } from "antd";
import { DownOutlined, LinkOutlined } from "@ant-design/icons";
import LetterAvatar from "react-letter-avatar";
import "./index.scss";
import { shareSource, sourceSharedUsers } from "api/Datasets";
import { useSelector } from "react-redux";

const ShareSourcesModal = ({ visible, sourceId, sourceName, onCancel }) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [sharedUsers, setSharedUsers] = useState([]);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const authReducer = useSelector((state) => state.authReducer);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const clearAllData = () => {
    setEmail("");
    onCancel();
  };

  const fetchSharedUsers = async () => {
    setLoadingUsers(true);
    try {
      const response = await sourceSharedUsers(sourceId);
      if (!response.data.error) {
        setSharedUsers(response.data.data);
      } else {
        message.error(response.data.message || "Failed to fetch shared users.");
      }
    } catch (error) {
      message.error("An error occurred while fetching shared users.");
    } finally {
      setLoadingUsers(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchSharedUsers();
    }
  }, [visible]);

  const handleShare = async () => {
    if (!emailRegex.test(email)) {
      message.error("Please enter a valid email address.");
      return;
    }

    setLoading(true);

    try {
      const workspaceId = authReducer.userSession.selectedWorkspaceId;
      await shareSource({ email, sourceId, workspaceId });
      message.success("Invite sent successfully.");
      fetchSharedUsers();
      clearAllData();
    } catch (error) {
      message.error("Failed to send invite. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showShareMenu = (
    <Menu>
      <Menu.Item key="1">Shared</Menu.Item>
      <Menu.Item key="2">Not Shared</Menu.Item>
    </Menu>
  );

  const filteredUsers = sharedUsers.filter(
    (user) =>
      `${user.first_name} ${user.last_name}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.email.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="shareModal">
      <Modal
        title={`Share '${sourceName}'`}
        visible={visible}
        onCancel={() => clearAllData()}
        footer={null}
        className="share-modal"
      >
        <div className="share-modal__input-container">
          <Input
            placeholder="Enter user email"
            className="share-modal__input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            onClick={handleShare}
            type="primary"
            className="share-modal__share-button"
            loading={loading}
          >
            Share
          </Button>
        </div>

        {loadingUsers ? (
          <div className="upload-summary__loading-container">
            <Spin size="mediun" tip="Loading users..." />
          </div>
        ) : filteredUsers.length === 0 ? (
          <div className="share-modal__empty">
            <Empty description="Share now! As Source is yet to be shared with anyone" />
          </div>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={filteredUsers ? filteredUsers : []}
            renderItem={(user) => (
              <List.Item className="share-modal__user">
                <List.Item.Meta
                  avatar={
                    user.image_uri ? (
                      // <img
                      //   src={user.image_uri}
                      //   alt={user.first_name}
                      //   style={{ width: 40, height: 40, borderRadius: "50%" }}
                      // />
                      <LetterAvatar name={user.first_name} size={40} radius={40} />
                    ) : (
                      <LetterAvatar name={user.first_name} size={40} radius={40} />
                    )
                  }
                  title={
                    <div className="share-modal__user-info">
                      <span className="share-modal__user-name">{`${user.first_name} ${user.last_name}`}</span>
                      <span className="share-modal__user-email">{user.email}</span>
                    </div>
                  }
                />

                <div className="share-modal__status">
                  {user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                </div>

              </List.Item>
            )}
          />
        )}
      </Modal>
    </div>
  );
};

export default ShareSourcesModal;
