import { Table, Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import "./index.scss";
import { useEffect, useState } from "react";
import { getProfileAiTraits } from "api/Explorer";
import { useParams } from "react-router-dom";

const AITraits = () => {
  const { revId } = useParams();
  const [aiTraitsData, setAITraitsData] = useState([
    {
      enagement_score: "0",
      sms_engagement_score: "0",
      email_engagement_score: "0",
      direct_mail_engagement_score: "0",
      phone_engagement_score: "0",
    },
  ]);

  useEffect(() => {
    fetchProfileAITraits();
  }, []);

  const fetchProfileAITraits = () => {
    getProfileAiTraits(revId)
      .then((res) => {
        console.log("AI Traits API Response = ", res?.data?.data);
        if (res?.data?.data != null && res?.data?.data.length === 0) {
          setAITraitsData(uploadData);
        } else {
          setAITraitsData(res?.data?.data);
        }
      })
      .catch((err) => {
        console.log("AI Traits API Error", err);
      });
  };

  const uploadData = [
    {
      enagement_score: "0",
      sms_engagement_score: "0",
      email_engagement_score: "0",
      direct_mail_engagement_score: "0",
      phone_engagement_score: "0",
    },
  ];

  const aiTraitsColumnsConfig = [
    // {
    //   title: "EXPECTED REVENUE",
    //   dataIndex: "expectedRevenue",
    //   key: "expectedRevenue",
    //   width: 200,
    //   render: (text) => <div>{text}</div>,
    // },
    {
      title: "RETARGETING SCORE",
      dataIndex: "enagement_score",
      key: "enagement_score",
      width: 180,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "SMS SCORE",
      dataIndex: "sms_engagement_score",
      key: "sms_engagement_score",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "EMAIL SCORE",
      dataIndex: "email_engagement_score",
      key: "email_engagement_score",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "DIRECT SCORE",
      dataIndex: "direct_mail_engagement_score",
      key: "direct_mail_engagement_score",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
    {
      title: "CALL SCORE",
      dataIndex: "phone_engagement_score",
      key: "phone_engagement_score",
      width: 120,
      render: (text) => (
        <div>
          <Tooltip title="This will show how this score was calculated">
            <ExclamationCircleOutlined
              style={{ marginRight: 8, marginBottom: 10, cursor: "pointer" }}
            />
          </Tooltip>
          {text} (0-100)
        </div>
      ),
    },
  ];

  const createUploadColumns = (config) => {
    const columns = config.map((columnConfig) => ({
      title: columnConfig.title,
      dataIndex: columnConfig.dataIndex,
      key: columnConfig.key,
      render:
        columnConfig.render ||
        ((text) => (
          <div className="unified-profile__icon-row">
            <span>{text}</span>
          </div>
        )),
    }));
    return columns;
  };

  return (
    <div className="unified-profile">
      <Table
        className="unified-profile__table"
        columns={createUploadColumns(aiTraitsColumnsConfig)}
        dataSource={aiTraitsData}
        pagination={false}
        tableLayout="fixed"
      />
    </div>
  );
};

export default AITraits;
